import * as React from "react";

import Layout from "../../components/Layout";
import { TextBanner } from "../../components/molecules/TextBanner";
import { LayoutBox } from "../../components/atoms/LayoutBox";
import ToolsRoll from "../../components/ToolsRoll";

export default class FocusAreaIndexPage extends React.Component {
  render() {
    return (
      <Layout
        title="Focus Areas"
        description="An overview of the focus areas of our platform."
      >
        <TextBanner title="Our focus areas are..." />
        <LayoutBox>
          <ToolsRoll />
        </LayoutBox>
      </Layout>
    );
  }
}
