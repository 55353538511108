import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import { ToolPreview } from "./ToolPreview";

class ToolsRollTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: tools } = data.allMarkdownRemark;

    return (
      <div className="columns is-multiline is-centered is-justify-content-flex-start is-align-items-stretch ml-1 mr-1">
        {tools &&
          tools.map(({ node: tool }) => (
            <div className="column is-half mb-6" key={tool.id}>
              <ToolPreview tool={tool} />
            </div>
          ))}
      </div>
    );
  }
}

ToolsRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function ToolsRoll() {
  return (
    <StaticQuery
      query={graphql`
        query ToolsRollQuery {
          allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___title] }
            filter: { frontmatter: { templateKey: { eq: "focus-area" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 200)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  description
                  templateKey
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                        quality: 100
                        height: 200
                      )
                    }
                    extension
                    publicURL
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <ToolsRollTemplate data={data} count={count} />}
    />
  );
}
